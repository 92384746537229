.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.control-prev {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.control-next {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.pagination {
  /*display: inline-block;*/
  position: relative;
  margin: 0 auto;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  margin: 0 4px;
}

.pagination a:hover {
  cursor: pointer
}

.pagination__link {
  font-weight: normal;
}


.pagination__link--active a {
  background-color: #284A9A;
  color: #FFFFFF !important;
  border: 1px solid #284A9A;
  border-radius: 5px;

}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}


table.table-bordered-invoice > thead > tr > th{
  border: 1px solid #000;
}
table.table-bordered-invoice > tbody > tr > td{
  border: 1px solid #000;
}

@font-face {
  font-family: 'Courier New';
  src: local('Courier New'), url(./assets/fonts/cour.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Courier New Italic';
  src: local('Courier New Italic'), url(./assets/fonts/couri.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}



@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(./assets/fonts/Proxima_Nova_Regular.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Proxima Nova Italic';
  src: local('Proxima Nova Italic'), url(assets/fonts/Proxima_Nova_Italic.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Fira Sans';
  src: local('Fira Sans'), url(./assets/fonts/FiraSans-Medium.ttf) format('ttf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.proxima-nova {
  font-family: "Proxima Nova";
}
.proxima-nova-i {
  font-family: "Proxima Nova Italic";
}

.fira-sans {
  font-family : Fira Sans
}

.courier-new {
  font-family : Courier New
}

.courier-new-i {
  font-family : Courier New Italic
}
