.customDropdown .dropdown-toggle {
    width: 100%;
}

.MuiDrawer-paper {
    width: 100%;
}

div.text-editor {
    font-family: 'Signika';
}

input[type='checkbox'] {
    /* Double-sized Checkboxes */
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    transform: scale(1.5);
    padding: 10px;
}
