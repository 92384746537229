.page-title {
    font-family: Signika;
    font-weight: 800;
    font-size: 1.75em;
    display: flex;
    justify-content: center;
    text-align: center;
    color: rgb(40, 74, 154);
    margin-top: 30px;
}

.register-form-label {
    font-family: Signika;
    font-weight: 600 !important;
    font-size: 0.75em !important;
    color: rgb(142, 142, 142);
    margin-bottom: 7px;
}

.input-focus {
    box-shadow: rgb(158 202 237) 0px 0px 10px !important;
    border: 1px solid rgb(158, 202, 237) !important;
    border-radius: 5px !important;
    background-color: rgb(233, 243, 244) !important;

}

.input-group-prepend {
    border-width: 0;
    display: flex;
    align-items: center;
    background-color: #e9f3f4
}

.form-control-custom {
    border-width: 0 !important;
    background-color: #e9f3f4 !important;
    outline: none !important;
    box-shadow: none !important;
    font-family: Signika !important;
    font-weight: 600,
}

.input-group-text-prepend {
    border-width: 0;
    display: flex;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
}

.MuiTypography-body1 {
    font-size: 0.75em !important;
}